<template>
<v-container>
  <base-material-card
    color="primary"
    inline
    icon="mdi-account"
    class="ml-4 px-5 py-3"
  >
    <template v-slot:after-heading>
      <div class="display-1 font-weight-light">
        Informações Pessoais
      </div>
    </template>
    <v-row class="mx-2">
      <v-col md="6" cols="12" class="my-auto">
        <v-text-field
          color="primary"
          label="Nome"
          prepend-icon="mdi-card-account-details"
          v-model="nome"
          :disabled="tipoAutenticacao == 'cda'"
        />
      </v-col>
      <v-col md="6" cols="12" id="col-mpc" class="pt-1">
        <v-card-text class="pt-0">
          <label id="mpc">Perfis Associados</label>
          <v-chip-group show-arrows center-active>
            <v-chip color="#a2a8aa" text-color="white" v-for="(perfil, i) in perfis" :key="i">
              <v-icon left>mdi-label</v-icon>
              {{perfil}}
            </v-chip>
          </v-chip-group>
        </v-card-text>
      </v-col>
    </v-row>
    <v-row class="mx-2">
      <v-col cols="6">
        <v-text-field
          color="primary"
          label="Login"
          prepend-icon="mdi-account"
          :value="login"
          disabled
        />
      </v-col>
      <v-col cols="12" sm="6">
        <v-select
          id="usuario-tipo"
          v-model="tipo"
          name="tipo"
          label="Tipo do usuário"
          :items="['COLABORADOR', 'TERCEIRO']"
          class="mb-0 pb-0"
          prepend-icon="mdi-tag"
          :disabled="tipoAutenticacao != 'database'"
        />
      </v-col>
    </v-row>
    <v-row class="mx-2">
      <v-col :cols="tipoAutenticacao == 'cda' ? '12':'6'">
        <v-text-field
          color="primary"
          label="Email"
          prepend-icon="mdi-email"
          :value="email"
          disabled
        />
      </v-col>
      <v-col 
        v-if="tipoAutenticacao == 'database'"
        cols="6"
      >
        <v-text-field
          v-model="senha"
          :type="mostrarSenha ? 'text' : 'password'"
          label="Senha"
          prepend-icon="mdi-lock-outline"
          :append-icon="mostrarSenha ? 'mdi-eye' : 'mdi-eye-off'"
          @click:append="mostrarSenha = !mostrarSenha"
          validate-on-blur
          :disabled="tipoAutenticacao != 'database'"

        />
      </v-col>
    </v-row>
    <v-row class="mx-2">
      <v-col md="4" cols="6">
        <v-text-field
          color="primary"
          label="Cargo"
          prepend-icon="mdi-certificate"
          v-model="cargo"
          :disabled="tipoAutenticacao == 'cda'"
        />
      </v-col>
      <v-col md="4" cols="6">
        <v-text-field
          color="primary"
          label="Diretoria"
          prepend-icon="mdi-account-tie"
          v-model="diretoria"
          :disabled="tipoAutenticacao == 'cda'"
        />
      </v-col>
      <v-col md="4" cols="12">
        <v-text-field
          color="primary"
          label="Area Executiva"
          prepend-icon="mdi-tie"
          v-model="area_executiva"
          :disabled="tipoAutenticacao == 'cda'"
        />
      </v-col>
    </v-row>
    <v-row v-if="tipoAutenticacao == 'database'" class="mx-auto">
      <v-col cols="12" >
          <v-btn
            color="primary"
            min-width="100"
            style="float: right;"
            @click="saveUserProfile()"
          >
            Salvar
          </v-btn>
        </v-col>
    </v-row>
  </base-material-card>

  <base-material-card
    color="primary"
    inline
    icon="mdi-office-building"
    class="ml-4 px-5 pt-3 pb-8"
  >
    <template v-slot:after-heading>
      <div class="display-1 font-weight-light">
        Lista de Empresas Liberadas
      </div>
    </template>
    <v-row class="mx-2">
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="acessos"
          class="elevation-1"
          hide-default-footer
          disable-pagination
          no-results-text="Nenhum registro correspondente encontrado"
        />
      </v-col>
    </v-row>
  </base-material-card>
</v-container>
</template>

<script>
import AuthService from '@/services/AuthService';
import UsersService from '@/services/UsersService'

export default {
  mounted() {
    this.getInfoUser();
    this.getTipoAutenticacao()
  },

  data: () => ({
    id: 0,
    login: '',
    nome: '',
    perfis: [],
    email: '',
    tipo: null,
    cargo: null,
    diretoria: null,
    area_executiva: null,
    senha:null,
    mostrarSenha:false,
    tipoAutenticacao: null,
    loading:false,
    acessos: [],
    headers: [
      { text: 'Empresa', value: 'empresa' },
      { text: 'Perfil', value: 'perfil' }
    ],
  }),

  methods: {
    getInfoUser() {
      AuthService.getUserInfo()
      .then(responseData => Object.assign(this, responseData));
    },
    async getTipoAutenticacao() {
      this.tipoAutenticacao = await AuthService.getTipoAutenticacao();
    },
    saveUserProfile() {
      this.loading = true;

      const postData = {
        senha: this.senha,
        nome: this.nome,
        cargo:this.cargo,
        diretoria:this.diretoria,
        areaExecutiva: this.area_executiva,
        tipo: this.tipo
      }
      
      UsersService.saveUserProfile(this.id, postData)
        .then(res => {
          this.$toast.success('Usuário salvo com sucesso.', '', { position: 'topRight' });
        })
        .catch(err => {
          console.error(err);
          this.$toast.error('Ocorreu um erro ao salvar o usuário.', '', { position: 'topRight' });
        })
        .finally(() => { 
          this.loading = false;
        });
    },
  }
}
</script>

<style>
#mpc {
  margin-left: 19px;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.3);
  font-size: 12px;
}

#col-mpc .v-item-group {
  margin-left: 17px;
}

#col-mpc .v-slide-group--has-affixes {
  margin-left: -35px !important;
  width: 115% !important;
  max-width: 115% !important;
}
</style>